<template>
  <div id="app1">
    <div class="query-wrap">
      <div class="query menu">
        <div class="item" v-if="activeIndex == '1'">
          <span class="font_14">盒子状态：</span>
          <el-button
            :key="index"
            v-for="(e, index) in boxOption"
            @click="handleSelect(index, 'boxStatus')"
            :class="boxStatusNote == index ? 'primary' : ''"
            >{{ e }}</el-button
          >
        </div>
        <div class="item session1" v-if="activeIndex == '1'">
          <div class="shopBox" ref="shopBox" @click="treeClick">
            <i>组织：</i>
            <input type="text" readonly name="" id="" :value="checkedName" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox" v-if="activeIndex == '1'" ref="treeBox" v-show="treeIsShow" style="top: 30px">
            <el-input
              placeholder="请输入组织名称搜索"
              v-model="searchOrg"
              @change="searchOrg"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-tree
              node-key="orgcode"
              :data="shopsTree"
              :props="defaultProps"
              ref="tree"
              :default-expanded-keys="[orgcode]"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
            </el-tree>
            <div class="dialogBtn pt20 mb20 borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </div>
        <div class="item session1" v-if="activeIndex == '1'">
          <span class="font_14 ml10" v-if="activeIndex == '1'">盒子类型：</span>
          <el-select
            style="margin-right: 20px"
            v-if="activeIndex == '1'"
            v-model="boxType"
            placeholder="全部"
            @change="handleSelect(boxType, 'boxType')"
          >
            <el-option v-for="(item, index) in typeOptionObj" :key="index" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <el-input
            placeholder="请输入盒子编号、MAC-ID、端口号、SolutionID查询"
            v-model="keyword"
            class="input-with-select searchInput"
            @change="searchByKeywords()"
          >
            <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="searchByKeywords()"></el-button>
          </el-input>
        </div>
      </div>
    </div>

    <div class="operation">
      <!-- <el-button @click="handleAssign" class="primary">红外批量分配</el-button> -->
      <div style="position: relative" ref="customDialog" class="fr">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
        <button class="ag-grey-button bord ml10" v-on:click="setTrue()">
          <i class="fa fa-gear thd"></i>
        </button>
        <div id="custom-column-wrap" class="ag-custom-column bor fts" v-show="dialogIsShow">
          <div class="ag-layout-block">
            <span style="font-weight: bold">设置自定义列</span>
            <a href="javascript:;" @click="setDefult" id="reset-column" class="fr">恢复默认</a>
          </div>
          <div class="ag-layout-block hori ag-check-group">
            <template>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <div style="margin: 5px 0"></div>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox
                  v-for="item in copyCheckedData"
                  :label="item.key"
                  :key="item.key"
                  :disabled="item.disabled"
                  >{{ item.label }}</el-checkbox
                >
              </el-checkbox-group>
            </template>
          </div>
          <div class="ag-layout-block top">
            <button @click="getCheckTrue" type="button" class="ag-model-btn blue fts mr5">保存</button>
            <button @click="dialogCancel" type="button" class="ag-model-btn gray fts">取消</button>
          </div>
        </div>
      </div>
    </div>
    <div class="integratable">
      <el-table :data="tableData" v-loading="isFetching" @sort-change="sortChange" style="width: 100%" row-key="pkid">
        <el-table-column
          v-for="(item, index) in checkedData"
          :min-width="item.width"
          :key="index"
          :label="item.label"
          :fixed="item.fixed"
          :prop="item.key"
          :sortable="item.sortable"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="item.key === 'boxNo'">
              <status :data="scope.row.status" :showLabel="false" />
              <span type="text" size="small">{{ isEmpty(scope.row.boxNo) ? '--' : scope.row.boxNo }}</span>
            </div>
            <div v-else-if="item.key === 'shopName'">
              <el-tooltip class="item" effect="dark" :content="scope.row.shopName" placement="top">
                <span>{{ scope.row.shopName.slice(0, 10) }}{{ scope.row.shopName.length > 10 ? '...' : '' }}</span>
              </el-tooltip>
            </div>
            <div v-else-if="item.key === 'boxType'">
              {{ isEmpty(scope.row.boxType) ? '--' : typeOption[scope.row.boxType] }}
            </div>
            <div v-else-if="item.key === 'operation'">
              <el-button @click="restSecond(scope.row)" type="text" size="small">摄像头管理</el-button>
              <el-button type="text" size="small" @click="toDeviceInfo(scope.row)">设备信息查询</el-button>
              <el-button
                v-if="[0, 4].includes(scope.row.boxType) && activeTab === '0'"
                type="text"
                size="small"
                @click="generateSolution(scope.row)"
                >生成解决方案</el-button
              >
            </div>
            <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageOptions.pageNumber"
        :page-sizes="[10, 30, 50]"
        :page-size="100"
        layout="total,sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 修改密码 -->
    <el-dialog title="修改密码" custom-class="addDialog" :visible.sync="dialogFormVisible" :before-close="cancelSetpsd">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="盒子编号：" :label-width="formLabelWidth">
          <span>{{ form.boxNo }}</span>
        </el-form-item>
        <el-form-item label="MAC-ID：" :label-width="formLabelWidth">
          <span>{{ form.macId }}</span>
        </el-form-item>
        <el-form-item label="盒子访问用户名：" :label-width="formLabelWidth" prop="currUser">
          <el-input v-model="form.currUser" autocomplete="off" placeholder="请输入" maxlength="64"></el-input>
        </el-form-item>
        <el-form-item label="密码：" :label-width="formLabelWidth" prop="currPass">
          <div class="pswbox">
            <el-input
              v-model="form.currPass"
              autocomplete="off"
              placeholder="请输入"
              :type="password"
              maxlength="30"
            ></el-input>
            <i
              v-if="eyeshow"
              class="password-icon iconfont icon-icon-mimabubukejian"
              @click="
                eyeshow = !eyeshow
                password = 'text'
              "
            ></i>
            <i
              v-if="!eyeshow"
              class="password-icon iconfont icon-icon-mimakejian"
              @click="
                eyeshow = !eyeshow
                password = 'password'
              "
            ></i>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSetpsd">取 消</el-button>
        <el-button type="primary" @click="updateAgentCurrentAuth('ruleForm')">提交</el-button>
      </div>
    </el-dialog>
    <!-- 批量分配 -->
    <el-dialog title="批量分配" :visible.sync="dialogAssignVisible" class="dialogAssignVisible">
      <div class="upload flex alignCenter item">
        <span>导入文件：</span>
        <div class="elDiv">
          <el-input
            readonly
            placeholder="点击浏览按钮导入文件"
            v-model="uploadFileName"
            type="text"
            name="file"
            style="width: 300px"
          />
        </div>
        <el-upload
          class="upload-demo"
          ref="upload"
          accept="application/vnd.ms-excel"
          :action="uploadUrl"
          :headers="headerData"
          with-credentials="true"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :on-change="handleChange"
          :show-file-list="false"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" style="margin-left: 10px">浏览</el-button>
        </el-upload>
      </div>
      <p v-show="fileEmpty" class="errorShow mt5">文件不能为空！</p>
      <div slot="footer" class="assignDialog-footer flex alignCenter flex-between">
        <a :href="downloadTempUrl">下载模板</a>
        <div class="dialog-button">
          <el-button @click="cancelAssign">取消</el-button>
          <el-button type="primary" @click="submitUpload">提交</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogResultVisible" width="30%" @close="handleUploadResult">
      <div class="content">
        <p style="font-size: 14px">
          分配完成，<span style="color: #14bd14">{{ successNum }}</span
          >条分配成功，<span style="color: #f00">{{ errorList.length }}</span
          >条分配失败<span v-show="errorList.length > 0">，失败数据请检查后再次上传。</span>
        </p>
        <div class="errorContent" v-show="errorList.length > 0">
          <p style="font-size: 14px; margin-top: 10px">错误详情:</p>
          <p v-for="(item, index) in errorList" :key="index" style="font-size: 14px">
            行数{{ item.rowNum }},{{ item.messageList.join() }};
          </p>
        </div>
        <div class="errorContent" v-show="warnMsgList.length > 0">
          <p style="font-size: 14px; margin-top: 10px">警告信息:</p>
          <p v-for="(item, index) in warnMsgList" :key="index" style="font-size: 14px">
            行数{{ item.rowNum }},{{ item.messageList.join() }};
          </p>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="handleUploadResult">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogImportErrVisible" width="30%" @close="handleErrorReason">
      <p style="font-size: 14px">{{ importErrorReason }}</p>
      <div slot="footer">
        <el-button type="default" @click="handleErrorReason">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import treemixin from 'src/mixins/tree'
import tmpColumn from 'src/mixins/templateColumn'
import { upgrade, updateAgentCurrentAuth, getBoxList } from 'src/api/legacy'
import { httpUrl, storeHttp, storeHttpV2, formatLabel, generateTableData } from 'src/utils/common'
import { isEmpty } from 'src/utils/method.js'
import Status from 'src/components/Status'

export default {
  components: { Status },
  mixins: [treemixin, tmpColumn],
  props: {
    activeTab: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      checkedData: [
        {
          label: '盒子编号',
          key: 'boxNo',
          width: 120,
          sortable: true,
        },
        // {
        //   label: '盒子ID',
        //   key: 'agentid',
        //   width: 100,
        //   sortable: true,
        // },
        {
          label: '盒子MacID',
          key: 'macId',
          width: 120,
          sortable: true,
          disabled: true,
        },
        {
          label: '端口号',
          key: 'boxPort',
          width: 80,
          sortable: true,
        },
        {
          label: '盒子类型',
          key: 'boxType',
          width: 100,
          sortable: true,
        },
        {
          label: '盒子型号',
          key: 'boxModel',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '产品版本',
          key: 'productVersion',
          width: 100,
          sortable: true,
        },
        {
          label: '分配状态',
          key: 'toshop',
          width: 100,
          sortable: true,
        },
        {
          label: '客户名称',
          key: 'cname',
          width: 100,
          sortable: true,
        },
        {
          label: '门店名称',
          key: 'shopName',
          width: 160,
          sortable: true,
        },
        {
          label: '摄像头数量',
          key: 'cameraCount',
          width: 120,
          sortable: true,
        },
        {
          label: 'SolutionID',
          key: 'productId',
          width: 120,
          sortable: true,
        },
        {
          label: '操作',
          key: 'operation',
          width: 240,
          disabled: true,
          fixed: 'right',
        },
      ],
      copyCheckedData: [],
      checkedCities: [
        'boxNo',
        'macId',
        'boxType',
        'boxPort',
        'productVersion',
        'toshop',
        'cname',
        'shopName',
        'cameraCount',
        'operation',
        'boxModel',
        'productId',
      ], //默 认列
      disabledCheck: [
        '盒子编号',
        'MAC-ID',
        '盒子类型',
        '端口号',
        '入库日期',
        '分配状态',
        '客户名称',
        '门店名称',
        '操作',
        '摄像头数量',
      ],
      activeIndex: 1,
      boxStatusNote: 0,
      boxStatus: null,
      boxType: null,
      statusOption: ['全部', '未分配', '已分配'],
      boxOption: ['全部', '离线', '在线'],
      typeOption: ['新零售', '红外', '广告机', '安卓广告机', '安防', '数字哨兵'],
      typeBox: null,
      typeOptionObj: [
        {
          name: '全部',
          value: null,
        },
        {
          name: '新零售',
          value: 0,
        },
        {
          name: '红外设备',
          value: 1,
        },
        {
          name: '安防监控',
          value: 4,
        },
      ],
      keyword: '',
      fonturl: httpUrl, //测试环境请求前缀
      pageOptions: {
        pageNumber: 1,
        pageSize: 10,
      },
      // downloadurl: '',
      // bindCamera: [],
      // vendor: '-1',
      tableData: [],
      total: 0,

      dialogFormVisible: false,
      password: 'password',
      eyeshow: true,
      form: {
        boxNo: '',
        macId: '',
        pkid: '',
        currUser: '',
        currPass: '',
      },
      rules: {
        currUser: [
          {
            required: true,
            message: '请填写',
            trigger: 'blur',
          },
        ],
        currPass: [
          {
            required: true,
            message: '请填写',
            trigger: 'blur',
          },
        ],
      },
      formLabelWidth: '140px',
      dialogAssignVisible: false,
      fileEmpty: false,
      uploadFileName: '',
      uploadUrl: storeHttp + '/api/shop/batchAdd',
      headerData: JSON.parse(localStorage.getItem('headerData')),
      downloadTempUrl: storeHttp + '/api/shop/template',
      dialogResultVisible: false, // 分配结果弹框
      successNum: 0,
      errorList: [],
      warnMsgList: [],
      dialogImportErrVisible: false,
      importErrorReason: '',
      prop: '',
      order: '',
      dataSource: [],
      isFetching: true,
    }
  },
  watch: {
    pageOptions: {
      handler() {
        this.formatTableData()
      },
      deep: true,
    },
    uploadFileName(val) {
      if (val) {
        this.fileEmpty = false
      }
    },
  },
  computed: {
    downloadurl() {
      const boxStatus = this.boxStatus === null ? '' : this.boxStatus
      const boxType = this.boxType === null ? '' : this.boxType
      return (
        storeHttp +
        '/api/solution_package/getBoxList/download?keyword=' +
        this.keyword +
        '&orgcode=' +
        this.orgcode +
        '&boxType=' +
        boxType +
        '&toshop=' +
        this.activeIndex +
        '&onlineStatus=' +
        boxStatus
      )
    },
  },
  created() {
    this.copyCheckedData = [...this.checkedData]
    this.activeIndex = this.$route.query.toshop || '1'
  },
  mounted() {
    this.getTreeData().then(() => {
      this.overviewInit()
      this.$nextTick(() => {
        this.$refs.tree?.setCurrentKey(this.orgcode)
      })
    })
    window.onbeforeunload = () => {
      //缓存
      let obj = {
        // active: this.activeIndex,
        code: this.orgcode,
        keyword: this.keyword,
        treeName: this.checkedName,
        // vendor: this.vendor,
        status: this.boxStatus,
        boxType: this.boxType,
      }
      sessionStorage.setItem('BoxMng2', JSON.stringify(obj))
    }
  },
  methods: {
    isEmpty,
    generateSolution(row) {
      const { boxId } = row

      this.$router.push({
        name: 'box-query-solution',
        query: {
          ...this.$route.query,
          boxId,
          type: row.boxType,
        },
      })
    },
    openDialog(row) {
      this.form.pkid = row.pkid
      this.form.boxNo = row.boxNo
      this.form.macId = row.macId
      this.getAgent()
      this.dialogFormVisible = true
    },
    //设置
    getAgent() {
      //查询用户名
      upgrade
        .getAgent({
          pkid: this.form.pkid,
        })
        .then((res) => {
          if (res && res.status === 0) {
            this.form.currUser = res.data.curruser
          }
        })
    },
    // 显示设置自定义列
    setTrue() {
      const tableColumnKey = this.checkedData.map((item) => item.key)
      this.checkedCities = tableColumnKey
      this.handleCheckedCitiesChange(tableColumnKey)
      this.dialogIsShow = true
    },
    getCheckTrue() {
      //确认按钮
      this.checkedData = this.copyCheckedData.filter((item) => this.checkedCities.includes(item.key))
      this.dialogIsShow = false
    },
    // 恢复默认
    setDefult() {
      this.checkedCities = this.setCheck
      this.handleCheckedCitiesChange(this.checkedCities)
    },
    // 勾选全选按钮
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.copyCheckedData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.copyCheckedData.length
    },
    updateAgentCurrentAuth() {
      // 修改密码
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确认修改密码吗？', '修改密码可能会影响盒子对摄像头的访问', {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            type: 'warning',
          }).then(() => {
            let { pkid, currUser, currPass } = this.form
            let params = { pkid, currUser, currPass }
            updateAgentCurrentAuth(params).then((res) => {
              if (res.data) {
                this.dialogFormVisible = false
                this.$message({
                  message: '成功',
                  type: 'success',
                })
              } else {
                this.$message({
                  message: '失败',
                  type: 'success',
                })
              }
              this.resetForm('ruleForm')
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancelSetpsd() {
      this.dialogFormVisible = false
      this.resetForm('ruleForm')
    },
    handleSelect(key, type) {
      this[type] = key
      if (type == 'boxStatus') {
        this.boxStatusNote = key
        this[type] = key == '0' ? null : key - 1
      }
      //切换menu

      this.pageOptions.pageNumber = 1
      this.overviewInit()
    },

    async getAgentList() {
      let data = {
        keyword: this.keyword,
        toshop: this.activeIndex,
        orgcode: this.orgcode || '0',
        // vendor: this.vendor,
        // status: this.boxStatus,
        boxType: this.boxType,
        // pageindex: this.currentPage,
        // pagesize: this.pageSize,
        onlineStatus: this.boxStatus,
      }
      this.isFetching = true
      return getBoxList(data).then((res) => {
        if (res && res.status === 0) {
          this.dataSource = res.data || []
          // this.currentPage = 1
          this.total = res.data?.length || 0
          this.dataSource.forEach((v) => {
            v.toshop = v.toShop == '0' ? '未分配' : v.toShop == '1' ? '已分配' : ''
            // v.autobind = v.autobind === 1 ? '是' : '否'
            // v.boxType = this.typeOption[v.boxType]
            // v.boxPort = Number(v.boxPort)
            v.boxModel = v.boxModel || ''
            // v.cpuModel = v.cpuModel || ''
            // v.ramCapacity = v.ramCapacity || ''
            // v.hdCapacity = v.hdCapacity || ''
          })
          this.formatTableData()
          this.isFetching = false
        }
      })
    },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
      // this.overviewInit()
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
      // this.overviewInit()
    },
    overviewInit() {
      //页面初始化
      this.getAgentList()
    },
    async searchByKeywords() {
      await this.getAgentList()
      this.pageOptions.pageNumber = 1
    },
    formatTableData() {
      this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },

    restSecond(row) {
      // 0－新零售，1－红外智能一体机，2-广告机，4－安防，5－数字哨兵
      const ary = [0, 1, 4]
      if (!ary.includes(row.boxType)) return false
      const pathAry = [
        {
          path: '/box/query/camera',
          value: 0,
        },
        {
          path: '/box/query/deviceCamera',
          value: 1,
        },
        {
          path: '/box/query/monitor',
          value: 4,
        },
      ]
      this.$router.push({
        path: pathAry.filter((v) => v.value == row.boxType)[0].path,
        query: {
          pkid: row.boxId,
          productId: row.productId || '',
        },
      })
    },
    handleChange(file) {
      const fileName = file.name
      if (fileName.includes('.xls')) {
        this.uploadFileName = file.name
      } else {
        this.$message.error('只能上传xls文件')
      }
    },
    uploadSuccess(res) {
      if (res.status === 0) {
        this.dialogResultVisible = true
        this.successNum = res.data.successNum
        this.errorList = res.data.errMsgList
        this.warnMsgList = res.data.warnMsgList
      } else {
        this.dialogImportErrVisible = true
        this.importErrorReason = res.msg
        this.$refs.upload.clearFiles()
      }
    },
    uploadError() {
      this.$message.error('文件上传失败')
    },
    submitUpload() {
      if (this.uploadFileName) {
        this.$refs.upload.submit()
        this.dialogAssignVisible = false
      } else {
        this.fileEmpty = true
      }
    },
    // 打开批量分配弹框
    handleAssign() {
      this.dialogAssignVisible = true
    },
    handleUploadResult() {
      this.dialogResultVisible = false
      this.uploadFileName = ''
    },
    handleErrorReason() {
      this.dialogImportErrVisible = false
      this.uploadFileName = ''
    },
    cancelAssign() {
      this.dialogAssignVisible = false
      this.uploadFileName = ''
      this.fileEmpty = false
      this.$refs.upload.clearFiles()
    },
    // 跳转到设备信息页
    toDeviceInfo(row) {
      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      const accessToken = localStorage.getItem('accessToken')
      // const refreshToken = localStorage.getItem('refreshToken')
      window.open(
        href +
          '?accessToken=' +
          accessToken +
          '&boxId=' +
          row.boxId +
          '&username=' +
          username +
          '&type=0' +
          '&boxType=0'
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import './tab1.less';
</style>